import { OrderStatus } from '@/entities';

export class OrderStatusDecorator {
  private orderStatus: OrderStatus;
  private scheduled?: boolean

  constructor(orderStatus: OrderStatus, scheduled?: boolean) {
    this.orderStatus = orderStatus;
    this.scheduled = scheduled
  }

  statusName(): string {
    switch (this.orderStatus) {
      case 'CREATED':
        return this.scheduled ? '予約' : '新規注文';
      case 'ACCEPTED':
        return '受付完了';
      case 'DENIED':
        return '受付拒否';
      case 'READY':
        return '調理中';
      case 'COMPLETED':
        return '完了';
      case 'CANCELED':
        return 'キャンセル';
      case 'UNKNOWN':
        return 'その他';
      default:
        return 'その他';
    }
  }
}
