import React from 'react';
import styles from '@/styles/components/buttons/TableButtonCoordination.module.scss';

interface Props {
  onClick: () => void;
}

export const TableButtonCoordination: React.FC<Props> = ({ onClick }: Props) =>
  <div className={styles.button} onClick={onClick}>
    <p>連携開始</p>
    <img src='/icon/right_circle.svg' />
  </div>
