import styles from '@/styles/components/badges/HelpBadge.module.scss';
import Link from 'next/link';
import React from 'react';

interface Props {
  url: string;
}

export const HelpBadge: React.FC<Props> = ({ url }: Props) =>
  <Link href={url} passHref>
    <a target="_blank" rel="noopener noreferrer" className={styles.help_badge}>
      <img src='/icon/question_red.svg' />
      <span className={styles.text}>ヘルプ</span>
    </a>
  </Link>
