import { PlatformType } from '@/entities';

export class PlatformTypeDecorator {
  readonly platformType: PlatformType;

  constructor(platformType: PlatformType) {
    this.platformType = platformType;
  }

  name(): string {
    switch (this.platformType) {
      case PlatformType.BOTEATS:
        return 'Bot Eats';
      case PlatformType.DEMAECAN:
      case PlatformType.DEMAECAN_OFFICIAL:
        return '出前館';
      case PlatformType.MENU:
        return 'menu';
      case PlatformType.MENU_OFFICIAL:
        return 'menu';
      case PlatformType.SUKESAN:
        return '資さんうどんモバイルオーダー'
      case PlatformType.UBEREATS:
        return 'Uber Eats';
      case PlatformType.UBEREATS_OFFICIAL:
        return 'Uber Eats';
      case PlatformType.WOLT:
        return 'Wolt'
      case PlatformType.WOLT_OFFICIAL:
        return 'Wolt';
    }
  }

  icon(): string {
    switch (this.platformType) {
      case PlatformType.BOTEATS:
        return '/platform/boteats.png';
      case PlatformType.DEMAECAN:
      case PlatformType.DEMAECAN_OFFICIAL:
        return '/platform/demaecan.png';
      case PlatformType.MENU:
        return '/platform/menu.png';
      case PlatformType.MENU_OFFICIAL:
        return '/platform/menu.png';
      case PlatformType.SUKESAN:
        return '/platform/sukesan.png'
      case PlatformType.UBEREATS:
        return '/platform/ubereats.png';
      case PlatformType.UBEREATS_OFFICIAL:
        return '/platform/ubereats.png';
      case PlatformType.WOLT:
        return '/platform/wolt.png';
      case PlatformType.WOLT_OFFICIAL:
        return '/platform/wolt.png';
    }
  }
}
