import dayjs from 'dayjs';
import React, { useState, VFC } from 'react';
import { Report, reportTypeName, Store } from '@/entities';
import { Pagination } from '@/components/controls/Pagination';
import styles from '@/styles/components/lists/ReportList.module.scss';
import { TableButtonDownload } from '../buttons/TableButtonDownload';
import { TableButtonRetry } from '../buttons/TableButtonRetry';

interface ReportListProps {
  reports: Report[];
  stores: Store[];
  onDownload: (report: Report) => void;
  onRetry: (report: Report) => void;
}

const PER_PAGE = 10;

export const ReportList: VFC<ReportListProps> = ({ reports, stores, onDownload, onRetry }: ReportListProps) => {
  const [page, setPage] = useState(1);

  const onChange = (page: number) => {
    setPage(page + 1);
  };

  return (
    <div>
      <table className={styles.table}>
        <tr>
          <th>レポート内容</th>
          <th>申請日時</th>
          <th>対象店舗</th>
          <th>指定期間</th>
          <th>ステータス</th>
          <th></th>
        </tr>
        {reports.slice(PER_PAGE * (page - 1), PER_PAGE * page).map(report => (
          <Item
            report={report}
            stores={stores ?? []}
            onDownload={onDownload}
            onRetry={onRetry}
          />
        ))}
        {!reports.length && <EmptyView />}
      </table>
      {reports.length > 0 && (
        <div className={styles.pagination}>
          <Pagination
            currentPage={page - 1}
            perPage={PER_PAGE}
            totalCount={reports.length}
            onChange={onChange}
          />
        </div>
      )}
    </div>
  );
};

interface ItemProps {
  report: Report;
  stores: Store[];
  onDownload: (report: Report) => void;
  onRetry: (report: Report) => void;
}

const Item: VFC<ItemProps> = ({ report, stores, onDownload, onRetry }: ItemProps) => {
  const storeText =
    report.storeIds?.length
      ? report.storeIds.map(storeId => stores.find(store => store.id === storeId)?.name).join(', ')
      : 'すべての店舗'

  const statusText =
    ({
      'CREATED': 'リクエスト中',
      'READY': dayjs().isBefore(report.expireTime.toDate()) ? 'ダウンロード可能' : '期限切れ',
      'FAILED': 'エラーが発生しました'
    })[report.status]

  const button =
    ({
      'CREATED': <></>,
      'READY': dayjs().isBefore(report.expireTime.toDate())
        ? <TableButtonDownload onClick={() => onDownload(report)} />
        : <TableButtonRetry onClick={() => onRetry(report)}  />,
      'FAILED': <></>,
    })[report.status]

  return (
    <tr key={report.id}>
      <td>{reportTypeName(report.type)}</td>
      <td>{dayjs(report.createTime.toDate()).format('YYYY/MM/DD HH:mm')}</td>
      <td>{storeText}</td>
      <td>{dayjs(report.startTime.toDate()).format('YYYY/MM/DD')} - {dayjs(report.endTime.toDate()).format('YYYY/MM/DD')}</td>
      <td>{statusText}</td>
      <td>{button}</td>
    </tr>
  )
};

const EmptyView: VFC = () => (
  <p className={styles.empty}>まだリクエストがありません。</p>
);
