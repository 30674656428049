import React from 'react';
import styles from '@/styles/components/buttons/TableButtonRetry.module.scss';

interface Props {
  onClick: () => void
}

export const TableButtonRetry: React.FC<Props> = ({ onClick }) =>
  <button className={styles.button} onClick={onClick}>
    再度リクエスト
  </button>
