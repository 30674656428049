import { Order } from '@/entities';
import { OrderStatusDecorator, PlatformTypeDecorator } from '@/decorators';

export class OrderDecorator {
  private order: Order;

  constructor(order: Order) {
    this.order = order;
  }

  statusName(): string {
    return new OrderStatusDecorator(this.order.status, this.order.scheduled).statusName();
  }

  statusReasonDescription(): string {
    switch (this.order.statusReason) {
      case 'customer_canceled':
        return 'お客様が注文をキャンセルされました'
      case 'driver_canceled':
        return '配達員が注文をキャンセルしました'
      case 'platform_canceled':
        return `${this.platformName()}により、注文がキャンセルされました`
      case 'store_canceled':
        return '店舗が注文をキャンセルしました'
      default:
        return ''
    }
  }

  platformName(): string {
    return new PlatformTypeDecorator(this.order.platform).name()
  }

  platfromIcon(): string {
    return new PlatformTypeDecorator(this.order.platform).icon()
  }
}
