import React, { useCallback, useMemo } from 'react';
import moment, { Moment } from 'moment';
import { DateRangePicker, FocusedInputShape } from 'react-dates';

import 'moment/locale/ja';

interface Props {
  startDate: Date | null | undefined;
  endDate: Date | null | undefined;
  focusedInput: FocusedInputShape | null;
  maxMonths?: number;
  minDate?: Date;
  setFocusedInput: (focusedInput: FocusedInputShape | null) => void;
  onDatesChange: (startDate: Date | undefined, endDate: Date | undefined) => void;
}

const Picker: React.FC<Props> = ({ startDate, endDate, focusedInput, maxMonths, minDate, setFocusedInput, onDatesChange }: Props) => {
  const isOutsideRange = useCallback((date: Moment) => {
    if (date.isAfter(moment().endOf('day'))) return true;
    if (maxMonths) {
      switch (focusedInput) {
        case 'startDate':
          return date.isBefore(moment(endDate).subtract(maxMonths, 'months'));
        case 'endDate':
          return date.isAfter(moment(startDate).add(maxMonths, 'months'));
      }
    }
    return false;
  }, [startDate, endDate, maxMonths, focusedInput]);

  return useMemo(() => (
    <DateRangePicker
      startDate={startDate ? moment(startDate) : null}
      startDateId="StartDate"
      startDatePlaceholderText="開始日"
      endDate={endDate ? moment(endDate) : null}
      endDateId="EndDate"
      endDatePlaceholderText="終了日"
      onDatesChange={({ startDate, endDate }) => {
        onDatesChange(startDate?.startOf('day').toDate(), endDate?.endOf('day').toDate());
      }}
      focusedInput={focusedInput}
      onFocusChange={setFocusedInput}
      isOutsideRange={isOutsideRange}
      displayFormat={() => 'YYYY/MM/DD'}
      customArrowIcon={<img src="/icon/date_picker_arrow.svg" />}
      customInputIcon={<img src="/icon/date_picker_date.svg" />}
      inputIconPosition="after"
      minimumNights={0}
      minDate={minDate && moment(minDate)}
      hideKeyboardShortcutsPanel
    />
  ), [startDate, endDate, focusedInput, isOutsideRange, minDate, onDatesChange, setFocusedInput]);
};

export { Picker as DateRangePicker };
