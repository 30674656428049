import firebase from 'firebase';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/react';
import { Sidebar, Spinner } from '@/components';
import { Route } from '@/constants';
import { useAuthState } from '@/hooks';
import styles from '@/styles/components/layouts/withSidebar.module.scss';
import { hasLocalizedDescription } from '@/entities';

type Props = {
  loading: boolean
  error: Error | undefined
  children: React.ReactNode
};

export const WithSidebar = ({ loading, error, children }: Props): React.ReactElement => {
  const [auth, authLoading] = useAuthState();
  const router = useRouter();

  useEffect(() => {
    if (!auth && !authLoading) router.push(Route.LOGIN);
  }, [auth, authLoading, router]);

  useEffect(() => {
    if (!error) return;

    alert(errorMessage(error));
    notifyError(error);
  }, [error]);

  const selected = router.pathname === '/' ? '' : router.pathname.split('/')[1];

  if (!auth || authLoading) return <></>;

  return (
    <div className={styles.wrapper}>
      <div className={styles.sidebar}>
        <Sidebar selected={selected} />
      </div>
      <div className={styles.content}>{children}</div>
      {loading && <Spinner loading size='full' />}
    </div>
  );
};

const errorMessage = (error: Error): string => {
  if (hasDetails(error) && hasLocalizedDescription(error.details))
    return `${error.details.localizedTitle}\n${error.details.localizedDetail}`;

  if (isHttpsError(error)) {
    switch (error.code) {
      case 'aborted':
        return 'リクエストが中断されました。';
      case 'already-exists':
        return 'すでに存在しています。';
      case 'cancelled':
        return 'リクエストがキャンセルされました。';
      case 'data-loss':
        return 'データが破損しています。';
      case 'deadline-exceeded':
        return 'リクエストがタイムアウトしました。';
      case 'invalid-argument':
        return 'リクエストの形式に誤りがあります。';
      case 'not-found':
        return 'リソースが見つかりませんでした。';
      case 'out-of-range':
        return '有効な範囲を超えています。';
      case 'permission-denied':
        return 'この操作をする権限が有りません。'
      case 'unauthenticated':
        return '認証されていません。ログインし直すか、サポートへご連絡ください。'

      case 'failed-precondition':
      case 'resource-exhausted':
      case 'unavailable':
        return '現在サービが利用できません。時間をおいて再度試すか、サポートへご連絡ください。'

      case 'ok':
      case 'unimplemented':
      case 'internal':
      case 'unknown':
        return 'サーバーで不明なエラーが発生しました。'
    }
  }

  return '不明なエラーが発生しました';
};

const notifyError = (error: Error) => {
  if (isHttpsError(error) && ['invalid-argument', 'not-found', 'permission-denied', 'unauthenticated'].includes(error.code))
    return;

  Sentry.captureException(error);
}

const hasDetails = (error: unknown): error is { details: unknown } =>
  typeof error === 'object' && !!error && 'details' in error;

const isHttpsError = (error: Error): error is firebase.functions.HttpsError =>
  Object.prototype.hasOwnProperty.call(error, 'code')
  && Object.prototype.hasOwnProperty.call(error, 'details')
