import styles from '@/styles/components/badges/ErrorBadge.module.scss';
import React from 'react';

interface Props {
  title: string
  icon: 'warning'
}

export const ErrorBadge: React.FC<Props> = ({ title, icon }: Props) => {
  return (
    <span className={styles.badge}>
      <img src={`/icon/${icon}.svg`} /> {title}
    </span>
  );
};