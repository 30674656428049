import styles from '@/styles/components/forms/Input.module.scss';

interface Props {
  title: string;
  description?: string;
  placeholder?: string;
  type: 'text' | 'password' | 'email';
  onChange: (_: string) => void;
  value: string;
  message?: string;
  readOnly?: boolean;
}

const Input: React.FC<Props> = (props: Props) => {
  return (
    <div className={styles.input}>
      <p>{props.title}</p>
      {props.description && <a>{props.description}</a>}
      <input
        type={props.type}
        placeholder={props.placeholder}
        onChange={(event) => props.onChange(event.target.value)}
        value={props.value}
        className={props.readOnly ? styles.readonly : undefined}
        readOnly={props.readOnly}
      />
      {props.message && <div className={styles.error}>{props.message}</div>}
    </div>
  );
};

export { Input };
