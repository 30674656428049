import React from 'react';

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  condition: any
  children: React.ReactNode
}

export const If = ({ condition, children }: Props): React.ReactElement =>
  condition ? <>{children}</> : <></>;
