import React from 'react';
import dynamic from 'next/dynamic';
import type { Datum, Pie, PieConfig } from '@ant-design/charts';

interface Props {
  data: PieChartData[];
}

export interface PieChartData {
  value: number;
  groupName: string;
}

const chartColors = [
  '#5B8FF9',
  '#61DDAA',
  '#65789B',
  '#F6BD16',
  '#7262FD',
  '#78D3F8',
  '#9661BC',
  '#F6903D',
  '#008685',
  '#F08BB4',
];

export const PieChart: React.FC<Props> = (props: Props) => {
  const PieComponent = dynamic(() => import('@ant-design/charts').then((mod) => mod.Pie), { ssr: false }) as typeof Pie;

  const total = props.data.reduce((acc, d) => acc + d.value, 0);

  const config = {
    data: props.data,
    angleField: 'value',
    colorField: 'groupName',
    color: chartColors,
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: (ref: Datum) =>`${Math.round(ref.percent * 100)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    tooltip: {
      formatter: (data: Datum) => ({ name: data.groupName, value: `${Math.round(data.value / total * 100)}%` })
    },
  } as PieConfig;

  return <PieComponent {...config} />;
};
