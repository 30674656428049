import React from 'react';
import dynamic from 'next/dynamic';
import type { Column, ColumnConfig } from '@ant-design/charts';

interface Props {
  data: StackedBarPercentChartData[];
}

export interface StackedBarPercentChartData {
  xField: string;
  yField: number;
  groupName: string;
}

const chartColors = [
  '#E6F4FF',
  '#B8E1FF',
  '#9AC5FF',
  '#7DAAFF',
  '#5B8FF9',
  '#3D76DD',
  '#085EC0',
  '#0047A5',
  '#00318A',
  '#001D70',
];

export const StackedBarPercentChart: React.FC<Props> = (props: Props) => {
  const ColumnComponent = dynamic(() => import('@ant-design/charts').then((mod) => mod.Column), { ssr: false }) as typeof Column;

  const uniqueGroupName = Array.from(new Set(props.data.map((data) => data.groupName)));

  const config = {
    data: props.data,
    isPercent: true,
    isStack: true,
    xField: 'xField',
    yField: 'yField',
    seriesField: 'groupName',
    color: chartColors,
    xAxis: {
      type: 'timeCat',
      tickCount: 3,
    },
    yAxis: {
      label: {
        formatter: (value: string) => {
          return +value * 100;
        },
      },
    },
    legend: {
      custom: true,
      position: 'right',
      items: uniqueGroupName.map((groupName, index) => {
        return {
          value: groupName,
          name: groupName,
          marker: {
            symbol: 'square',
            style: {
              fill: chartColors[index % chartColors.length],
              r: 5,
            },
          },
        };
      }),
    },
  } as ColumnConfig;

  return <ColumnComponent {...config} />;
};
