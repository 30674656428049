import React from 'react';
import { PosItem } from '@/entities';
import 'react-dropdown/style.css';
import styles from '@/styles/components/controls/DropdownPosSelector.module.scss';

interface Props {
  posItems: Pick<PosItem, 'id' | 'name' | 'price'>[]
  selectedId?: string
  onClick: () => void
}

export const ScrollablePosSelectorControl: React.FC<Props> = ({ posItems, selectedId, onClick }: Props) => {
  const selectedPosItem = posItems.find(posItem => posItem.id === selectedId);

  return (
    <div className={styles.view}>
      <div className={styles.select} onClick={() => onClick()}>
        <p className={selectedPosItem ? styles.selected : styles.unSelected}>{selectedPosItem ? selectedPosItem.name : '選択する'}</p>
        <img src='/icon/down.svg' />
      </div>
    </div>
  );
};
