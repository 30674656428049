import { IntegratedPlatform } from '@/hooks';
import { Item, PlatformType, PosItem, unIntegratedPosItemCountOfPlatform } from '@/entities';
import { PlatformTypeDecorator } from '@/decorators';
import styles from '@/styles/components/views/PlatformTabView.module.scss';

interface Props {
  integratedPlatforms: IntegratedPlatform[]
  selectedPlatformType: PlatformType | undefined
  items: Omit<Item, 'createTime' | 'updateTime'>[] // TODO: move
  posItems: PosItem[] // TODO: move
  onClick: (platformType: PlatformType) => void
}

export const PlatformTabView: React.FC<Props> = ({ integratedPlatforms, selectedPlatformType, items, posItems, onClick }: Props) =>
  <ul className={styles.platforms}>
    {integratedPlatforms.map(({ platformType, platform }) => {
      const className = platformType === selectedPlatformType
        ? styles.selectedPlatform
        : platform
          ? ''
          : styles.emptyPlatform;

      const count = platform
        ? unIntegratedPosItemCountOfPlatform(platform, items, posItems)
        : 0;

      return (
        <li key={platformType} onClick={() => onClick(platformType)} className={className}>
          <img src={new PlatformTypeDecorator(platformType).icon()} className={styles.icon}/>
          <p className={styles.platFormName}>{new PlatformTypeDecorator(platformType).name()}</p>
          <div className={styles.badge}>
            {count > 0 && <div className={styles.count}>{count}</div>}
          </div>
        </li>
      )
    })}
  </ul>;
