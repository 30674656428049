import React from 'react';
import { Step, Stepper as ReactStepper } from 'react-form-stepper';
import styles from '@/styles/components/controls/Stepper.module.scss';

interface Props {
  steps: string[];
  currentIndex: number;
}

export const Stepper: React.FC<Props> = ({ steps, currentIndex }) => (
  <div className={styles.container}>
    <ReactStepper
      activeStep={currentIndex}
      className={styles.stepper}
      connectorStateColors={true}
      connectorStyleConfig={connectorStyle}
      styleConfig={stepStyle}>
      {steps.map((step, i) => (
        <Step
          key={step}
          label={step}
          className={styles.step}
          active={i === currentIndex}
          completed={i < currentIndex}>
          <div className={i === currentIndex ? styles.stepActive : ''} />
        </Step>
      ))}
    </ReactStepper>
  </div>
);

const connectorStyle = {
  disabledColor: 'var(--c-gray10)',
  activeColor: 'var(--c-gray100)',
  completedColor: 'var(--c-gray100)',
  size: 4,
  stepSize: '2em',
  style: 'solid',
};

const stepStyle = {
  activeBgColor: 'var(--c-gray100)',
  activeTextColor: 'var(--c-gray100)',
  completedBgColor: 'var(--c-gray100)',
  completedTextColor: 'var(--c-gray100)',
  inactiveBgColor: 'var(--c-gray10)',
  inactiveTextColor: 'var(--c-gray10)',
  size: 12,
  circleFontSize: 0,
  labelFontSize: 10,
  borderRadius: 2,
  fontWeight: 0,
};
