import styles from '@/styles/components/charts/TimelineChart.module.scss';

interface TimelineChartItem {
  timeText: string
  dateText: string
  mainText: string
  subText?: string
  color: 'black' | 'red'
  isLatest: boolean
}

interface Props {
  timelineChartItems: TimelineChartItem[]
  title: string
  extra?: React.ReactNode
}

export const TimelineChart: React.FC<Props> = ({ timelineChartItems, title, extra }: Props) => (
  <div className={styles.wrapper}>
    <div className={styles.header}>
      <div className={styles.title}>{title}</div>
      {extra}
    </div>
    <div className={styles.body}>
      <ul>
        {timelineChartItems.map(({ timeText, dateText, mainText, subText, color, isLatest }) => (
          <li>
            <div className={styles.timeline_icon}>
              {
                color === 'red'
                  ? isLatest
                    ? <img src='/icon/timeline_error_latest_item.svg' />
                    : <img src='/icon/timeline_error_item.svg' />
                  : isLatest
                    ? <img src='/icon/timeline_latest_item.svg' />
                    : <img src='/icon/timeline_item.svg' />
              }
            </div>
            {
              color === 'red'
                ? <div className={styles.timeline_content_red}>
                  <p>
                    <span className={styles.hour}>{timeText}</span>
                    <span className={styles.day}>{dateText}</span>
                    <span className={styles.main}>{mainText}</span>
                  </p>
                  <p>
                    <span className={styles.sub}>{subText}</span>
                  </p>
                </div>
                : <div className={styles.timeline_content_black}>
                  <p>
                    <span className={styles.hour}>{timeText}</span>
                    <span className={styles.day}>{dateText}</span>
                    <span className={styles.main}>{mainText}</span>
                  </p>
                  <p>
                    <span className={styles.sub}>{subText}</span>
                  </p>
                </div>
            }
          </li>
        ))}
      </ul>
    </div>
  </div>
)