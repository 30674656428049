import React from 'react';
import Link from 'next/link';
import styles from '@/styles/components/layouts/Card.module.scss';

interface Props {
  header?: {
    title: string;
    descriptionView?: React.ReactNode;
    backUrl?: string;
    extraView?: React.ReactNode;
    onChangeSearch?: (search: string) => void;
  };
  hideBorder?: boolean;
  children: React.ReactNode;
}

export const Card: React.FC<Props> = (props: Props) => {
  return (
    <section className={`${styles.card} ${props.hideBorder && styles.hideCardBorder}`}>
      {props.header && <Header {...props} />}
      {props.children}
    </section>
  );
};

const Header = (props: Pick<Props, 'header' | 'hideBorder'>) => (
  <div className={`${styles.header} ${props.hideBorder && styles.dispHeaderBorder}`}>
    <div>
      {props.header?.backUrl ? (
        <div className={styles.back}>
          <Link href={props.header.backUrl}>
            <a>
              <img src="/icon/back.svg" />
            </a>
          </Link>
          <h3>{props.header.title}</h3>
        </div>
      ) : (
        <h3>{props.header?.title}</h3>
      )}
      {props.header?.onChangeSearch && (
        <div className={styles.search}>
          <img className={styles.searchIcon} src={'icon/search.svg'} />
          <input
            className={styles.searchInput}
            type="search"
            placeholder="店舗名を入力"
            onChange={event => props.header?.onChangeSearch?.(event.target.value)} />
        </div>
      )}
      {props.header?.extraView && props.header.extraView}
    </div>
    {props.header?.descriptionView && props.header.descriptionView}
  </div>
);
