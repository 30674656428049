import { findCandidatePosItemsForItem, Item, PosItem, Similarity } from '@/entities';
import { useCallback, useMemo, useRef, useState } from 'react';
import styles from '@/styles/components/controls/ScrollablePosSelector.module.scss';
import { PrimaryButton } from '../buttons/PrimaryButton';
import { NewBadge } from '../badges/NewBadge';
import * as Formatter from '@/utils';
import { normalizeSentence } from '@/utils';
import { If } from '@/components';
import { useTokenizer } from '@/utils/hooks/useTokenizer';

interface Props {
  fulfillment: 'delivery' | 'pick_up'
  item: Item
  posItems: PosItem[]
  similarityMap: Record<string, Similarity>

  onSelect: (item: Item, fullfillment: 'delivery' | 'pick_up', posId: string | undefined) => void
}

export const ScrollablePosSelector : React.FC<Props> = ({ fulfillment, item, posItems, similarityMap, onSelect }: Props) => {
  const { tokenizer } = useTokenizer();
  const [search, setSearch] = useState<string>();

  const [selectedPosId, setSelectedPosId] = useState<string | undefined>(
    posItems.find(posItem => posItem.itemIds.includes(item.id))?.id
  );

  const posItemsWithHighMatchingRate = useMemo(
    () => findCandidatePosItemsForItem(item, posItems, similarityMap)
    , [item, posItems, similarityMap]
  );

  const posItemsWithoutHighMatchingRate = useMemo(() => posItems
    .filter(posItem => !posItemsWithHighMatchingRate?.some(pWHMR => pWHMR.id === posItem.id))
  , [posItems, posItemsWithHighMatchingRate]);

  const inputRef = useRef<HTMLInputElement>(null);
  const searchedPosItems = tokenizer && posItems
    .filter(posItem => search
      ? (posItem.id.indexOf(search) > -1 || normalizeSentence(posItem.name, tokenizer).indexOf(normalizeSentence(search, tokenizer)) > -1)
      : true
    );

  const renderCategory = useCallback((item: Item) =>
    item.categories && item.categories?.length > 0 ? ` / ${item.categories.map(category => category).join('・')}` : ''
  , []);

  const renderTable = useCallback((label?: string, posItems?: Pick<PosItem, 'id' | 'name' | 'price' | 'itemIds'>[]) =>
    <table key={`table${label}`}>
      <If condition={label}>
        <thead key={`thead${label}`}><div>{label}</div></thead></If>
      <If condition={posItems?.length !== 0}>
        <tbody key={`tbody${label}`}>
          <If condition={label}>
            {
              posItems?.map((posItem, i) => <tr key={`tr${label}${i}`} onClick={() => setSelectedPosId(posItem.id)} className={`${selectedPosId === posItem.id && styles.trSelected}`}>
                <td key={`td${i}_${posItem.id}`}>
                  <div className={styles.checkBox}>
                    <a className={selectedPosId === posItem.id? styles.after : styles.before}/>
                  </div>
                  <div className={styles.item}>
                    <If condition={!isAssociatedWithItems(posItem)}>
                      <span className={styles.badgeWrapper}><NewBadge color='warning'/></span>
                    </If>
                    <span className={styles.name}>{posItem.name}</span>
                  </div>
                  <div className={styles.posId}>{posItem.id}</div>
                  <div className={styles.price}>{Formatter.price(posItem.price)}</div>
                  <div className={styles.badge}>
                    <If condition={isAssociatedWithItems(posItem)}>
                      <a className={styles.pfStatus}>選択済み</a>
                    </If>
                  </div>
                </td>
              </tr>
              )
            }
          </If>
          <If condition={!label}>
            <tr onClick={() => setSelectedPosId(undefined)} className={`${!selectedPosId && styles.trSelected}`}>
              <td>
                <div className={styles.checkBox}><a className={selectedPosId ? styles.before : styles.after}/></div>
                <div className={styles.item}><span className={styles.name}>選択しない</span></div>
                <div className={styles.posId} />
                <div className={styles.price} />
                <div className={styles.badge} />
              </td>
            </tr>
          </If>
        </tbody>
      </If>
    </table>
  , [selectedPosId])

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <span className={styles.category}>
          商品 {renderCategory(item)}
        </span>
        <span className={styles.title}>
          {item.title}・{Formatter.price(item.price?? 0)}
        </span>
        <div className={styles.search}>
          <img className={styles.searchIcon} src={'/icon/search.svg'}/>
          <input
            ref={inputRef}
            type='text'
            placeholder='商品名, オプション名, POSID'
            className={styles.searchInput}
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </div>
      </div>
      <div className={styles.body}>
        <If condition={search}>
          {renderTable(searchedPosItems?.length === 0? '一致する検索結果はありません' : '検索結果', searchedPosItems)}
        </If>
        <If condition={!search}>
          {renderTable()}
          <If condition={posItemsWithHighMatchingRate?.length > 0}>
            {renderTable('マッチング率が高い', posItemsWithHighMatchingRate)}
          </If>
          {renderTable('すべての商品・オプション', posItemsWithoutHighMatchingRate)}
        </If>
      </div>
      <div className={styles.footer}>
        <PrimaryButton size='medium' title='確定' onClick={() => onSelect(item, fulfillment, selectedPosId)}/>
      </div>
    </div>
  )
};

const isAssociatedWithItems = (posItem : Pick<PosItem, 'itemIds'>) =>
  posItem.itemIds.length > 0;
