import React, { Fragment } from 'react';
import { Brand, Order } from '@/entities';
import * as Formatter from '@/utils';
import { OrderDecorator } from '@/decorators';
import styles from '@/styles/components/lists/OrderList.module.scss';

interface Props {
  brands: Brand[];
  orders: Order[];
  onPress: (order: Order) => void;
}

export const OrderList: React.FC<Props> = ({ brands, orders, onPress }: Props) => {
  const HeaderView = () => {
    return (
      <div className={styles.header}>
        <ul>
          <li className={styles.col_fulfillment} key="header_fulfillment">
            提供方法
          </li>
          <li className={styles.col_status} key="header_status">
            ステータス
          </li>
          <li className={styles.col_platform} key="header_platform">
            プラットフォーム
          </li>
          <li className={styles.col_order_number} key="header_order_number">
            注文番号
          </li>
          <li className={styles.col_order_name} key="header_order_name">
            注文者
          </li>
          <li className={styles.col_brand} key="header_brand">
            ブランド
          </li>
          <li className={styles.col_total} key="header_total">
            合計
          </li>
        </ul>
      </div>
    );
  };

  const ItemView = (props: { order: Order; brand: Brand | undefined }) => {
    const FulfillmentCell = () => {
      return (
        <li className={styles.col_fulfillment} key={`${props.order.id}_fulfillment`}>
          <div className={styles.fulfillment}>
            {props.order.deliveryMethod == 'eat_in' && 'イートイン'}
            {props.order.deliveryMethod == 'pick_up' && 'テイクアウト'}
            {props.order.deliveryMethod == 'delivery' && (props.order.inHouseDelivery ? '店舗スタッフ' : '配達')}
          </div>
          <div className={styles.time}>{Formatter.time(props.order.createTime.toDate())}</div>
        </li>
      );
    };

    const StatusCell = () => {
      const decorator = new OrderDecorator(props.order);

      return (
        <li className={styles.col_status} key={`${props.order.id}_status`}>
          <div
            className={
              props.order.status != 'CANCELED' ? styles.status : styles.status__cancel
            }>
            {decorator.statusName()}
          </div>
        </li>
      );
    };

    const PlatformCell = () => {
      const decorator = new OrderDecorator(props.order);

      return (
        <li className={styles.col_platform} key={`${props.order.id}_platform`}>
          <img src={decorator.platfromIcon()} className={styles.icon} />
        </li>
      );
    };

    const OrderNumberCell = () => {
      return (
        <li className={styles.col_order_number} key={`${props.order.id}_order_number`}>
          <div className={styles.id}>{props.order.displayId}</div>
        </li>
      );
    };

    const OrderNameCell = () => {
      return (
        <li className={styles.col_order_name} key={`${props.order.id}_order_name`}>
          <div className={styles.name}>{props.order.customer.name}</div>
        </li>
      );
    };

    const BrandCell = () => {
      return (
        <li className={styles.col_brand} key={`${props.order.id}_brand`}>
          <div className={styles.brand}>
            <div className={styles.brand_name}>{props.brand?.name}</div>
          </div>
        </li>
      );
    };

    const TotalCell = () => {
      return (
        <li className={styles.col_total} key={`${props.order.id}_total`}>
          {Formatter.price(props.order.price.total)}
        </li>
      );
    };

    return (
      <ul onClick={() => onPress(props.order)}>
        <FulfillmentCell />
        <StatusCell />
        <PlatformCell />
        <OrderNumberCell />
        <OrderNameCell />
        <BrandCell />
        <TotalCell />
      </ul>
    );
  };

  return (
    <Fragment>
      <HeaderView />
      <div className={styles.list}>
        {orders.map((order) => (
          <ItemView
            key={order.id}
            order={order}
            brand={brands.find((element) => element.id == order.brandId)}
          />
        ))}
      </div>
    </Fragment>
  );
};
