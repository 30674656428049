import { price } from '@/utils';
import React from 'react';
import dynamic from 'next/dynamic';
import type { Column, ColumnConfig, Datum } from '@ant-design/charts';

interface Props {
  data: StackedBarChartData[];
  unit: 'percent' | 'price' | 'number';
}

export interface StackedBarChartData {
  xField: string;
  yField: number;
  groupName: string;
}

const chartColors = [
  '#5B8FF9',
  '#61DDAA',
  '#65789B',
  '#F6BD16',
  '#7262FD',
  '#78D3F8',
  '#9661BC',
  '#F6903D',
  '#008685',
  '#F08BB4',
];

export const StackedBarChart: React.FC<Props> = (props: Props) => {
  const ColumnComponent = dynamic(() => import('@ant-design/charts').then((mod) => mod.Column), { ssr: false }) as typeof Column;

  const uniqueGroupName = Array.from(new Set(props.data.map((data) => data.groupName)));

  const config = {
    data: props.data.map(data => ({ ...data, yField: props.unit === 'percent' ? data.yField * 100 : data.yField })),
    isStack: true,
    xField: 'xField',
    yField: 'yField',
    seriesField: 'groupName',
    color: chartColors,
    xAxis: {
      tickCount: 3,
    },
    legend: {
      custom: true,
      position: 'right',
      items: uniqueGroupName.map((groupName, index) => {
        return {
          value: groupName,
          name: groupName,
          marker: {
            symbol: 'square',
            style: {
              fill: chartColors[index % chartColors.length],
              r: 5,
            },
          },
        };
      }),
    },
    tooltip: {
      formatter: (data: Datum) => ({ name: data.groupName, value: format(props.unit, data.yField) })
    }
  } as ColumnConfig;

  return <ColumnComponent {...config} />;
};

const format = (unit: 'percent' | 'price' | 'number', value: number): string => {
  switch(unit) {
    case 'percent': return value + '%';
    case 'price': return price(value);
    case 'number': return value + '';
  }
}
