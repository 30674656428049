import React from 'react';
import styles from '@/styles/components/buttons/PrimaryButton.module.scss';

interface Props {
  title: string;
  size: 'small' | 'medium' | 'large';
  disabled?: boolean;
  onClick: () => void;
}

export const PrimaryButton: React.FC<Props> = ({ title, size, disabled, onClick }: Props) =>
  <button className={`${style(size)} ${disabled && styles.disabled}`} onClick={onClick} >
    {title}
  </button>

const style = (size: 'small' | 'medium' | 'large') =>
  ({ small: styles.small, medium: styles.medium, large: styles.large})[size]