import { Item, } from '@/entities';
import * as Formatter from '@/utils';
import styles from '@/styles/components/lists/PosItemList.module.scss';
import { If } from '@/components';

interface Props {
  item: Item;
  hasAssociatingWarning: boolean;
  usePickupPosItem: boolean;
}

export const ItemContent: React.FC<Props> = ({ item, hasAssociatingWarning, usePickupPosItem }: Props) =>
  <div className={styles.masterMenu}>
    <div className={styles.iconWarning}>
      <If condition={hasAssociatingWarning}><img src="/icon/warning.svg" /></If>
    </div>
    <div className={styles.itemName}>
      <p>
        {item.title}
        {' '}
        {item.categories?.map(category => <a key={`itemContent-${item.id}-category-${category}`}>{category}</a>)}
      </p>
    </div>
    <div className={styles.prices}>
      <div className={styles.price}>
        {usePickupPosItem && <p>デリバリー</p>}
        <p>{Formatter.price(item.price ?? 0)}</p>
      </div>
      {usePickupPosItem && <div className={styles.price}><p>テイクアウト</p></div>}
    </div>
  </div>;
