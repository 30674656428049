import React from 'react';
import { Store } from '@/entities';
import styles from '@/styles/components/views/PosSummaryView.module.scss';

interface Props {
  posName?: string;
  store?: Pick<Store, 'name'>;
}

const PosSummaryView: React.FC<Props> = (props: Props) => {
  return props.posName || props.store ? (
    <div className={styles.wrapper}>
      {props.posName && (
        <div className={styles.platform}>
          <img src="/icon/pos.svg" />
          <p>{props.posName}</p>
        </div>
      )}
      {props.store && (
        <div className={styles.store}>
          <p>店舗名:</p>
          <p>{props.store.name}</p>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

export { PosSummaryView };
