import styles from '@/styles/components/forms/DateTime.module.scss';
import dayjs from 'dayjs';
import { ReactElement, useState } from 'react';

interface Props {
  title: string;
  description: string;
  onChange: (_: string) => void;
  value: string;
  message?: string;
}

const RADIO_GROUPS = [
  { name: 'すぐに適用する', value: 'now' }, // TODO: Make this as argument
  { name: '日時を指定する', value: 'schedule' },
];

type Type = 'now' | 'schedule';

const DateTime = (props: Props): ReactElement => {
  const [type, setType] = useState<Type>('now');
  const [dateTime, setDateTime] = useState(dayjs().second(0).millisecond(0));

  const date = dateTime.format('YYYY-MM-DD');
  const time = dateTime.format('HH:mm');

  const onChangeType = (type: Type) => {
    if (type === 'now') {
      setDateTime(dayjs().second(0).millisecond(0));
      props.onChange(dayjs().second(0).millisecond(0).toISOString());
    }
    setType(type);
  };

  const onChangeDate = (dateString: string) => {
    const [year, month, date] = dateString.split('-').map(string => +string);
    const d = dateTime.year(year).month(month - 1).date(date);
    setDateTime(d);
    props.onChange(d.toISOString());
  };

  const onChangeTime = (timeString: string) => {
    const [hour, minute] = timeString.split(':').map(string => +string);
    const date = dateTime.hour(hour).minute(minute).second(0).millisecond(0);
    setDateTime(date);
    props.onChange(date.toISOString());
  };

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{props.title}</p>
      <p className={styles.description}>{props.description}</p>
      <fieldset className={styles.fieldset}>
        <a onClick={() => onChangeType('now')} key='Radio-option-now'>
          <input className={styles.radioInput} type='radio' checked={RADIO_GROUPS[0].value === type}/>
          <label className={styles.label}>{RADIO_GROUPS[0].name}</label>
        </a>
        <a onClick={() => onChangeType('schedule')} key='Radio-option-schedule'>
          <input className={styles.radioInput} type='radio' checked={RADIO_GROUPS[1].value === type}/>
          <label className={styles.label}>{RADIO_GROUPS[1].name}</label>
        </a>
      </fieldset>
      {type === 'schedule' && (
        <div className={styles.inputs}>
          <input
            type="date"
            className={styles.dateInput}
            onChange={event => onChangeDate(event.target.value)}
            value={date}/>
          <input
            type="time"
            className={styles.timeInput}
            onChange={event => onChangeTime(event.target.value)}
            step={5 * 60}
            value={time}/>
        </div>
      )}
      {props.message && <div className={styles.error}>{props.message}</div>}
    </div>
  );
};

export { DateTime };
