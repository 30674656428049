import { PosItem, } from '@/entities';
import * as Formatter from '@/utils';
import styles from '@/styles/components/lists/PosItemList.module.scss';
import { ErrorBadge, If, ScrollablePosSelectorControl } from '@/components';

interface Props {
  editable: boolean;
  hasPriceWarning: boolean;
  posItems: PosItem[];
  posItem: PosItem | undefined;
  pickupPosItem: PosItem | undefined;
  usePickupPosItem: boolean;

  onOpenModalForPosItem: () => void;
  onOpenModalForPickupPosItem: () => void;
}

export const PosItemContent: React.FC<Props> = ({
  editable,
  hasPriceWarning,
  posItems,
  posItem,
  pickupPosItem,
  usePickupPosItem,
  onOpenModalForPosItem,
  onOpenModalForPickupPosItem
}: Props) =>
  <div className={styles.menus}>
    <div className={styles.menu}>
      <div className={styles.item}>
        <If condition={editable}>
          <ScrollablePosSelectorControl {...{ posItems: posItems, selectedId: posItem?.id, onClick: () => onOpenModalForPosItem() }}/>
        </If>
        <If condition={!editable}><p>{posItem?.name}</p></If>
      </div>
      <p className={styles.pos}>{posItem?.id}</p>
      <div className={styles.priceBox}>
        <If condition={hasPriceWarning}><br/></If>
        <p className={styles.price}>{posItem?.price && Formatter.price(posItem.price)}</p>
        <If condition={hasPriceWarning}><div className={styles.badgeWrapper}><ErrorBadge title='価格不一致' icon='warning'/></div></If>
      </div>
    </div>
    <If condition={usePickupPosItem}>
      <div className={styles.menu}>
        <div className={styles.item}>
          <If condition={editable}>
            <ScrollablePosSelectorControl {...{ posItems: posItems, selectedId: pickupPosItem?.id, onClick: () => onOpenModalForPickupPosItem() }}/>
          </If>
          <If condition={!editable}>
            {pickupPosItem?.name}
          </If>
        </div>
        <p className={styles.pos}>{pickupPosItem?.id}</p>
        <p className={styles.price}>{pickupPosItem?.price && Formatter.price(pickupPosItem.price)}</p>
      </div>
    </If>
  </div>;
