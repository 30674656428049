import { FC } from 'react';
import Switch from 'react-switch';

interface Props {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

export const Toggle: FC<Props> = (props: Props) => {
  return (
    <Switch
      checked={props.checked}
      onChange={props.onChange}
      onColor='#0074e0'
      onHandleColor='#ffffff'
      handleDiameter={22}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow='0px 0px 2px rgba(0, 0, 0, 0.6)'
      height={12}
      width={32}
    />
  );
};
