import React from 'react';
import Link from 'next/link';
import styles from '@/styles/components/buttons/IconButton.module.scss';

interface Props {
  title: string;
  href: string;
  icon: 'add' | 'download';
}

const IconButton: React.FC<Props> = (props: Props) => {
  return (
    <Link href={props.href}>
      <a className={styles.button}>
        <img src={`/icon/${props.icon}.svg`} /> {props.title}
      </a>
    </Link>
  );
};

export { IconButton };
