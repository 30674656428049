import { PosPlatformType } from '@/entities';

export class PosTypeDecorator {
  private posType: PosPlatformType;

  constructor(posType: PosPlatformType) {
    this.posType = posType;
  }

  name(): string {
    switch (this.posType) {
      case 'linkto': return 'Seiko (Linkto)';
      case 'ngp': return '東芝TEC (OrderLinkage)';
      case 'stesso': return '東芝TEC (stesso)';
    }
  }
}
