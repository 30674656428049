import { Item, PosItem } from '@/entities';
import { PosItemListItem } from '@/components/lists/PosItemList/PosItemListItem';
import styles from '@/styles/components/lists/PosItemList.module.scss';

type EditProps =
  | {
    editable: true;
    onOpenModal: (item: Item, fulfillment: 'delivery' | 'pick_up') => void;
  }
  | {
    editable: false;
  }

type Props = {
  platformName: string | undefined;
  posItems: PosItem[];
  items: Item[];
  useDisplayOnlyItemsWithPriceMismatch: boolean;
  useDisplayOnlyUnselected: boolean;
  usePickupPosItem: boolean;

  editProps: EditProps
}

export const PosItemList: React.FC<Props> = ({
  platformName,
  items,
  posItems,
  useDisplayOnlyItemsWithPriceMismatch,
  useDisplayOnlyUnselected,
  usePickupPosItem,
  editProps
}: Props) =>
  <div className={styles.table}>
    <div className={styles.header}>
      <div className={styles.masterMenu}>
        <h3>{platformName}のメニュー</h3>
        <p>価格</p>
        <img src="/icon/tie.svg" />
      </div>
      <div className={styles.menu}>
        <h3>POSのメニュー</h3>
        <p className={styles.pos}>POS ID</p>
        <p className={styles.price}>価格</p>
      </div>
    </div>
    <div className={styles.tableBody}>
      <ul>
        {items.map(item => <PosItemListItem
          key={`posItemListItem-${item.id}`}
          {...{ posItems, item, useDisplayOnlyItemsWithPriceMismatch, useDisplayOnlyUnselected, usePickupPosItem, editProps }} />)}
      </ul>
    </div>
  </div>;
